.configuration-menu {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    min-height: 400px;
    max-height: 730px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding-bottom: 20px !important;
}

.children-inner-container-with-bg-1{
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  background-color: white;
  overflow-y: scroll;
  height: 100vh;
}
.configuration-submenu-container {
    height: 100%;
    display: block;
    flex-direction: column;
    justify-content: initial !important;
    flex-wrap: nowrap !important;
}

.configuration-submenu {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    min-height: 50px;
    padding-left: 10px;
    margin: 5px 15px 10px 15px !important;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.icon-configuration {
    font-size: 20px;
}

.drive-details-label-configuration {
    font-size: 14px;
    font-weight: bold;
    color: black;
    width: 130px;
}

.configuration-submenu:hover {
    background-color: #f0f0f0;
}

.configuration-submenu.selected {
    background-color: var(--primaryColor);
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.5);
}

.configuration-submenu.selected {
    background-color: var(--primaryColor);
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.5);
}

.configuration-submenu.selected span {
    color: white;
}

.selected-option {
    text-align: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding-top: 30px !important;
    padding-right: 20px !important;
    padding-left: 10px !important;
    overflow-y: auto;
    max-height: 730px;
}

.subcontainer-configuration {
    padding-left: 15px !important;
    padding-top: 0px !important;
}

.title-users {
    color: var(--primaryColor);
    font-size: 20px;
    font-weight: bold;
}

.grid-title-users {
    display: flex;
    margin-left: 25px !important;
    align-items: center;
}

.search-users {
    display: flex;
    align-items: center;
}

.subtitle-users-value {
    font-size: 12px;
    color: gray;
}

.title-users-value {
    font-size: 16px;
    font-weight: bold;
}

.title-users-value-1 {
    font-size: 16px;
    font-weight: bold;
}

.status-user-value-green {
    display: inline-block;
    width: 50px;
    height: 30px;
    background-color: #4caf50;
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
    color: white;
}

.status-user-value-red {
    display: inline-block;
    width: 70px;
    background-color: rgb(198, 79, 79);
    font-size: 11px;
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
    color: white;
}

.status-user-value-gray {
    display: inline-block;
    width: 55px;
    height: 30px;
    background-color: rgb(126, 123, 123);
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
    color: white;
}

.item-user-list {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.modal-user-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 8px;
    text-align: start;
}

.modal-user-detail {
    position: absolute;
    min-width: 100px;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 8px;
    text-align: start;
    z-index: 1000;
}

.button-enviar {
    background-color: var(--primaryColor);
    color: white !important;
}


.textField-input {
    padding-top: 0.4em !important;
    margin-bottom: 0em !important;
}

.input-value-user {
    width: 100%;

}

.divider-user {
    margin: 3px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.input-value-user .MuiInputBase-input {
    height: 15px !important;
}

.MuiOutlinedInput-input {
    padding: 8px 14px !important;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.button-enviar {
    background-color: var(--primaryColor) !important;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: none;
}

.button-enviar:hover {
    background-color: var(--primaryColor);
}

.user-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.left {
    flex: 1;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.search-users,
.pagination-controls {
    margin-left: 10px;
}

button {
    margin-left: 5px;
}