.basic-indicator-container{
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow:hidden;
}

.basic-indicator-icon{
    color: white;
    color: gray;
    font-size: 95px;
}

.basic-indicator-label{
    font-size: .875rem;
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}

.basic-indicator-cumulative{
    font-size: 2rem;
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}

.basic-indicator-value{
    font-size: 13px;
    color: white;
    font-weight: 700;
}

.basic-indicator-icon2{
    color: white;
}

.basic-indicator-graph{
    margin-top: 10px !important;
    
}

