body{
    background-image: url('https://res.cloudinary.com/productsview/image/upload/v1670597397/loginCooltrack.png');
    background-color: #F7F5FE;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-wrap {
    width: 100%;
    
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.login-form {
    margin: 80px auto;
    width: 400px;
    display: block;
    padding: 53px 30px 10px 30px;
    border-radius: 30px;
    border: solid 3px white;
    box-shadow: -8px 8px 20px -3px #ccc;
    background-color: rgba(250, 249, 254, 0.7);
    backdrop-filter: blur(6px);
}

.login-form-title {
    width: 100%;
    display: block;
    font-size: 23px;
    font-weight: 700;
    color: #000000;
    text-align: left;
}

.login-form-subtitle {
    width: 100%;
    display: block;
    font-size: 14px;
    color: #adadad;
    line-height: 1.2;
    text-align: left;
    padding-bottom: 43px;
}

.login-submit{
    width: 100%;
    float: left;
    margin-top: -50px;
    background-color: Red;
    border-color: Red;
    border-radius: 10px;
    box-shadow: 0px 10px 10px -6px gray
}

.login-submit:active, .login-submit:hover, .login-submit:focus{
    /* height: 55, */
    background-color: grey;
    border-color: grey;
}

.flex-sb-m {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 32px;
    width: 100%;
}

.reset-password {
    float: right;
    margin-top: -25px;
    color: #D10505;
    font-weight: normal;
}

.reset-password:hover, .reset-password:focus, .reset-password:active{
    color: #4931a0;
}

.login-background {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.login-background-brand {
    background-image: url('https://navstorageapps.blob.core.windows.net/nav-cooltrack-evidences/image-BrandingSmallUM.jpg');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    max-width: 100%;
    min-height: 100px;
    top: 20%;
}

.login-background-brand-sm {
    display: block;
    background-image: url('https://navstorageapps.blob.core.windows.net/nav-cooltrack-evidences/image-BrandingSmallUM.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;
    min-height: 90px;
    margin-bottom: 30px;
}


@media screen and (max-width: 870px) {
    .login-background-brand {
        display: none;
    }

    .login-form {
        width: 70%;
    }

    .login-form-title {
        font-size: 20px;
    }
    
    .login-form-subtitle {
        font-size: 14px;
    }

    .reset-password {
        font-weight: normal;
    }
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-size: 10px;
}