.form-element {
  width: 20vw !important;
}

.form-element-with-button {
  width: 20vw !important;
}

.form-element2 {
  margin-top: 10px !important;
}

.ExcelGeneratorCabecera {
  margin-left: 750px;
}

.ExcelGeneratorLinea {
  margin-left: 10px;
}

.asterisco {
  color: red;
}

.upload-button {
  width: 300px;
  height: 200px;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.25);
}

.upload-button:hover {
  background-color: #aa0404;
  border-color: #aa0404;
}

.Info-Geo-Clouser {
  margin-top: -40px;
  width: 300px;
  color: red;
}

.children-inner-container-with-bg-upload {
  display: flex;
  margin: 20px;
  padding: 20px;
  width: 1616px;
  height: 790px;
  border-radius: 15px;
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  background-color: white;
}

.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  margin: 20px;
  background-color: white;
}

.tab-content {
  margin-bottom: 20px;
  min-height: 500px;
  align-content: center;
  width: 120vh;
}

.options-list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.option-items {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option-item:hover {
  background-color: #f0f0f0;
}

.option-item.selected {
  color: rgb(179, 0, 0);
  border-bottom: 3px solid rgb(181, 0, 0);
}

.manual-upload-container {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.button-manual-upload {
  background-color: var(--primaryColor);
  color: white;
  margin-bottom: 20px;
  border-radius: 5px;
  align-self: flex-end;
  width: 157px;
  height: 50px;
}

.button-manual-upload:hover {
  background-color: rgb(179, 46, 46);
}

.button-back {
  background-color: white; 
  color: #D10505; 
  border: 1px solid #D10505; 
  padding: 10px 20px; 
  border-radius: 5px; 
  display: flex;
  align-items: center; 
  font-size: 14px; 
  cursor: pointer; 
  margin-bottom: 20px; 
  transition: background-color 0.3s, color 0.3s; 
}

.button-back:hover {
  background-color: #f1f1f1; 
  color: #D10505; 
}

.button-back svg {
  margin-right: 8px; 
}


.button-container-2{
  align-self: flex-end; 
  margin-right: 65px;
}

.option-content{
  align-content: center;
}

.css-1vismrg-MuiFormControl-root-MuiTextField-root{
  margin-top: 50px !important;
}

.option-content-special{
  align-content: center;
  max-height: 500px;
  text-align: center;
}


.header-container {
  display: flex;
  align-items: center; 
  width: 100%; 
}

.button-container {
  flex-shrink: 0; 
  margin-left: 25px; 
}

.title-container {
  flex-grow: 1; 
  text-align: center; 
}

.title-manual-upload {
  color: var(--primaryColor);
  font-size: 30px;
  font-weight: bold;
  margin: 0; 
}

.option-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option-item {
  display: flex;  
  align-items: center;  
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin: 5px 0;  
  font-weight: bold;
}

.option-item:hover {
  background-color: #f0f0f0;
}

.icon {
  margin-right: 10px;  
}

.icon svg {
  font-size: 24px; 
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  min-width: 300px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  max-height: 260px !important;
  max-width: 300px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  font-size: 14px !important;
  height: 24px !important;
}