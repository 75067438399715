.ant-menu-inline, .ant-menu-inline-collapsed{
  margin-top: 5px !important;
}

.ant-menu-item{
  background-color: none;
  color: #212121;
  height: 42px !important;
  transition: all 0.3s;
}

.ant-menu-item a{
  color: #212121 !important;
}

.ant-menu-item.ant-menu-item-selected.menuselected {
  background-color: var(--primaryColor);
  color: white;
  box-shadow: 0 10px 5px -8px rgba(0,0,0,0.4);
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline{
  border: none;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border: var(--primaryColor);
}

.ant-menu-item-selected a{
  color: white !important;
}

.ant-menu-item-selected span{
  font-weight: 600;
}

.iconMenu {
  font-size: 22px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  transition: background-color 0.3s ease, color 0.3s ease; 
  color: #333 !important; 
}

.ant-menu-item-selected .iconMenu {
  color: #fff !important; 
}

.ant-menu-item-selected a {
  color: #fff !important; 
}

.menuselected:hover {
  background-color: rgba(169, 169, 169, 0.2); 
  color: #333; 
  border-radius: 4px; 
}

.menu-title {
  padding: 0px 16px 0px 10px !important;
  font-size: 16px;
  font-weight: bold;
  background-color: white !important;  
  color:black !important;
  margin-bottom: 0px !important;
  height: 30px !important;
}

.menu-title-general {
  padding: 0px 16px 0px 10px !important;
  font-size: 16px;
  font-weight: bold;
  background-color: white !important;  
  color:black !important;
  margin-bottom: 0px !important;
  height: 30px !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  display: flex;
  align-items: center;  
}

.ant-menu-inline-collapsed .ant-menu-item .iconMenu {
  font-size: 22px !important;  
  margin-top: 7px !important;
}

.ant-layout-sider-collapsed .ant-menu-item.menu-title-general {
  margin-top: 50px !important;
}

.ant-menu:not(.ant-menu-inline-collapsed) .ant-menu-item {
  font-size: 16px; 
}

.ant-menu-item .iconMenu {
  margin-right: 15px; 
}
