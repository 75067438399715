.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}
  
.row-dragging td {
    padding: 16px;
    visibility: hidden;
}
  
.row-dragging .drag-visible {
    visibility: visible;
}

.ant-collapse {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: absolute;
    right: 5px;
    left: 5px;
    bottom: 5px;
    border-radius: 20px;
    box-shadow: 1px 1px 5px gray;
}

.ant-card {
    border-radius: 10px;
    box-shadow: 1px 1px 5px gray;
    min-width: 100%;
    min-height: 100%;
}

.scheduling-screen-container{
    padding-left: 40px;
    padding-right: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.scheduling-screen-inner-col-container1{
    height: calc(100vh - 106px);
    opacity: 0.95;
}
.form-element1{
    width: 100%;
   margin-left: 100px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.scheduling-screen-inner-col-container-hidden{
    display: none;
}

.scheduling-screen-inner-container12{
    transition: 0.5s all;
    background-color: white;
    height: calc(45% - 10px);
    width: 100%;
    min-width: 300px;
    float: left;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.scheduling-screen-inner-container13{
    transition: 0.5s all;
    background-color: white;
    height: calc(45% - 10px);
    width: 100%;
    min-width: 300px;
    float: left;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.scheduling-screen-inner-container14{
    height: 97px;
    width: 100%;
}

.scheduling-screen-inner-container16{
    height: 97px;
    width: 100%;
}

.scheduling-screen-inner-container17{
    height: 97px;
    width: 100%;
}

.scheduling-screen-inner-container151{
    background-color: white;
    height: calc(45% - 10px);
    width: 100%;
}

.scheduling-screen-inner-container152{
    max-height: 35vh;
    width: 100%;
}

.scheduling-screen-inner-container153{
    max-height: 35vh;
    width: 100%;
}

.scheduling-screen-list-container11{
    margin-top: 34px;
    height: calc(100% - 34px);
    overflow-y: auto; 
}

.scheduling-screen-list-container11-selected{
    height: 100%;
    overflow-y: auto; 
}

.scheduling-screen-list-container11-title{
    width: 100%;
    min-width: 300px;
    height: 30px;
    margin-top: 4px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    color: #212121;
    text-align: center;
    position: absolute;
}

.scheduling-screen-list-container11-title-selected{
    width: 100%;
    height: 30px;
    margin-top: 4px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    color: #212121;
    text-align: center;
}

.scheduling-screen-list-item-row-container{
    margin: 5px;
    margin-left: 4px;
    margin-right: 0px;
    padding: 5px;
    height: 110px;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: white; 
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    overflow-y: auto;
}

.scheduling-screen-list-item-label-1{
    font-size: 12px;
}

.scheduling-screen-list-item-label-2{
    font-size: 17px;
    font-weight: 700;
}

.scheduling-screen-list-item-label-3{
    font-size: 10px;
}

.scheduling-screen-list-item-label-4{
    font-size: 10px;
}

.scheduling-screen-list-item-label-5{
    font-size: 10px;

}

.scheduling-screen-list-item-progress{
   margin-top: -2px;
}

.orders-list-screen-list-container11{
    margin-top: 34px;
    height: calc(100% - 34px);
    overflow-y: auto; 
    
}

.orders-list-screen-list-container11-selected{
    margin-top: 34px;
    height: calc(100% - 34px);
    overflow-y: auto; 
    
}

.orders-vehicle-list-screen-list-container11{
    height: 100%;
    overflow-y: auto; 
}

.orders-vehicle-list-screen-list-container11-selected{
    height: calc(100% - 34px);
    overflow-y: auto; 
}

.orders-list-screen-list-item-row-container{
    margin: 5px;
    margin-left: 4px;
    margin-right: 0px;
    padding: 5px;
    height: 240px;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color:white; 
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    transition: 0.5s all;
    overflow-y: auto;
}

.orders-list-screen-list-item-row-container-time-window{
    margin: 5px;
    margin-left: 4px;
    margin-right: 0px;
    padding: 5px;
    height: 230px;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: white; 
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    transition: 0.5s all;
}

.orders-list-screen-list-item-row-container-time-window-order{
    margin: 5px;
    margin-left: 4px;
    margin-right: 0px;
    padding: 5px;
    height: 180px;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: white; 
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    transition: 0.5s all;
    overflow-y: auto;
}

.orders-list-screen-list-item-row-container-selected{
    background-color: #9ccc65;
    transition: 0.5s all;
    color: white;
    overflow-y: auto;
    
}

.orders-list-screen-list-item-row-container-selected2{
    background-color: var(--primaryColor);
    transition: 0.5s all;
    color: white;
    
}

.scheduling-screen-list-item-row-container-selected{
    background-color: #343a40;
    transition: 0.5s all;
    color: white;
    overflow-y: auto;
}

.orders-list-screen-list-item-row-container-selected3{
    background-color: #572EE9;
    transition: 0.5s all;
    color: white;
    padding-left: 8px;
    margin-right: 4px;
    
    
}

.orders-list-screen-list-item-label-1{
    font-size: 14px;
    font-weight: 700;
}

.orders-list-screen-list-item-label-2{
    overflow-y: auto;
    height: 40px;
    font-size: 9px;
}
.orders-list-screen-list-item-label-29{
    overflow-y: auto;
    margin-top: 5px;
    height: 40px;
    font-size: 9px;
    font-weight: bold; 
}

.orders-list-screen-list-item-label-3 {
    width: 100px;
    font-size: 11px;
    font-weight: bold; 
    margin-top: 0px;
    margin-top: -2%;
}


.orders-list-screen-list-item-label-4{
    font-size: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 600;
}

.orders-list-screen-list-item-label-5{
    font-size: 10px;
    font-weight: 600; 
}

.orders-list-screen-list-item-label-6{
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
    text-align: center;
    width: 100%; 
}


.orders-list-screen-list-item-label-7{
    font-size: 10px;
    height: 24px;
    padding-bottom: -14px;
}

.orders-list-screen-list-item-label-80{
    font-size: 9px;
    height: 24px;
    padding-bottom: -14px;
}

.orders-list-screen-list-item-label-81{
    font-size: 9px;
    height: 24px;
    padding-top: 5px;
}

.orders-list-screen-list-item-label-8{
    font-size: 10px;
    margin-left: 98%;
    margin-top: -35px;
}
.orders-list-screen-list-item-label-50{
    font-size: 10px;
    margin-left: 98%;
    margin-top: -15px;
    width: 100%;
}
.orders-list-screen-list-item-label-51{
    font-size: 10px;
    font-weight: bold;
}
.orders-list-screen-list-item-label-52{
    font-size: 10px;
    font-weight: bold;
}
.orders-list-screen-list-item-label-9{
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    margin-left: -5px;
    width: 100%; 
}

.orders-list-screen-list-item-label-11{
    font-size: 10px;
    height: 24px;
    margin-top: -8px;
    font-weight: 700;
}

.orders-list-screen-list-item-label-12{
    font-size: 10px;
    height: 24px;
    font-weight: 700;
}

.orders-list-screen-list-item-label-10{
    font-size: 10px;
    height: 24px;
    margin-top: -8px;
}

.orders-list-screen-list-item-label-13{
    font-size: 24px;
    margin-top: -18px;
}
.orders-list-screen-list-item-label-20{
    overflow-y: auto;
    font-size: 9px;
}
.orders-list-screen-list-item-label-21{
    overflow-y: auto;
    font-size: 9px;
}
.orders-list-screen-list-item-label-22{
    font-size: 10px;
    margin-top: 1px;
    width: 100px;
    max-width:100px;
}
.orders-list-screen-list-item-label-23{
    height: 35px;
    width: 50px;
    font-size: 10px;
    margin-top: 0px;
    margin-top: -2%; 
}
.orders-list-screen-list-item-label-24{
    height: 35px;
    width: 50px;
    font-size: 10px;
    margin-top: -15px;
}
.orders-list-screen-list-item-label-25{
    height: 35px;
    width: 50px;
    font-size: 10px;
    margin-top: -5px;
    font-weight: bold;
}
.orders-list-screen-list-item-label-26{
    height: 35px;
    width: 50px;
    font-size: 13px;
    margin-top: -2px;
    font-weight: bold;
    margin-bottom: 12px;
}
.orders-list-screen-inner-container14 .orders-list-screen-list-item-row-container{
    
    background-color: #343a40;
    transition: 0.5s all;
    color: white;
}

.orders-list-screen-inner-container16 .orders-list-screen-list-item-row-container{
    background-color: var(--primaryColor);
    transition: 0.5s all;
    color: white;
}

.orders-list-screen-inner-container17 .orders-list-screen-list-item-row-container{
    background-color: #9ccc65;
    transition: 0.5s all;
    color: white;
}

.orders-list-screen-list-state{
    height: 24px;
    width: 30px;
    border-radius: 3px;
    text-align: center;
    font-weight: 600;
}

.orders-list-screen-info-window{
    width: 200px;
    height:60px;
}

.orders-list-screen-info-window-container{
    width: 250px;
}

.scheduling-screen-list-button-container{
    padding-top: 5px;
    padding-bottom: 5px;
}

.scheduling-screen-list-button{
    border: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 100%;
    width: 18px;
    padding: 0px !important;
}

.scheduling-screen-list-button-init{
    border: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 50%;
    width: 18px;
    padding: 0px !important;
}


.scheduling-screen-list-button-end{
    border: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 50%;
    width: 18px;
    padding: 0px !important;
}


.scheduling-screen-list-button-container2{
    margin-top: 3px;
    height: 87px;
}

.scheduling-screen-list-button-container2-selected{
    margin-top: -30px;
    padding-bottom: 8px;
    height: 100%;
}

.scheduling-screen-list-button2init{
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 25%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-button2up{
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 25%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-button2center{
    border: none;
    border-radius: 0;
    height: 25%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-button2down{
    border: none;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 25%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-filtered-button2down{
    border: none;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 20%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-filtered-button2up{
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 20%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-filtered-button2init{
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 20%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.scheduling-screen-list-filtered-button2center{
    border: none;
    border-radius: 0;
    height: 20%;
    width: 18px;
    padding: 0px !important;
    margin: 0px !important;
}